import { Link } from 'gatsby';
import React from 'react';
import { TextButton, TextButtonIcon } from '../../components/Button';
import { FlexBox } from '../../components/FlexBox';
import styles from './PostFooter.module.scss';

export const PostFooter: React.FC<any> = ({ previous, next }) => (
  <>
    {(previous || next) && (
      <FlexBox
        component="ul"
        justifyContent="space-between"
        padding={0}
        margin={4}
        style={{ listStyle: 'none' }}
      >
        <li>
          {previous && (
            <TextButton
              component={Link}
              to={previous.slug}
              rel="prev"
              iconPosition="left"
              marginBottom={2}
            >
              <TextButtonIcon iconType="arrow-l" />
              <span className={styles.previousTitle}>{previous.title}</span>
            </TextButton>
          )}
        </li>
        <li>
          {next && (
            <TextButton component={Link} to={next.slug} rel="next">
              <TextButtonIcon iconType="arrow-r" />
              <span className={styles.nextTitle}>{next.title}</span>
            </TextButton>
          )}
        </li>
      </FlexBox>
    )}
  </>
);
