import React from 'react';
import { Post } from '../../blog/components/Post';

const BlogPost = ({ location, data }) => {
  const { blogPost, previous, next } = data;

  return (
    <Post
      data={{ ...data, post: blogPost }}
      location={location}
      previous={previous}
      next={next}
    />
  );
};

export default BlogPost;
