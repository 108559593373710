import Image from 'gatsby-image';
import React from 'react';
import styles from './PostHero.module.scss';

export const PostHero = ({ post }) => (
  <>
    {post?.image?.childImageSharp && (
      <Image
        className={styles.postHero}
        fluid={post.image.childImageSharp.fluid}
        alt={post.imageAlt ? post.imageAlt : post.excerpt}
      />
    )}
  </>
);
