import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { TextButton, TextButtonIcon } from '../../components/Button';
import { Divider } from '../../components/Divider';
import { Layout } from './Layout';
import styles from './Post.module.scss';
import { PostDate } from './PostDate';
import { PostFooter } from './PostFooter';
import { PostHero } from './PostHero';
import { PostTitle } from './PostTitle';
import SEO from './SEO';

const rootPath = `${__PATH_PREFIX__}/blog`;

export const Post: React.FC<any> = ({ data: { post }, previous, next }) => (
  <Layout>
    <SEO
      title={post.title}
      description={post.excerpt}
      imageSource={
        post.socialImage
          ? post.socialImage?.childImageSharp?.fluid.src
          : post.image?.childImageSharp?.fluid.src
      }
      keywords={post.keywords}
      imageAlt={post.imageAlt}
    />
    <TextButton
      component={Link}
      to={rootPath}
      marginBottom={4}
      sizeVariant="mini"
      iconPosition="left"
    >
      <TextButtonIcon iconType="arrow-l" />
      Back to all posts
    </TextButton>
    <div className={styles.main}>
      <PostHero post={post} />
      <div className={styles.content}>
        <PostTitle>{post.title}</PostTitle>
        <PostDate>{post.date}</PostDate>
        <Divider />
        <MDXRenderer>{post.body}</MDXRenderer>
      </div>
    </div>
    <PostFooter {...{ previous, next }} />
  </Layout>
);
