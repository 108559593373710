import classNames from 'classnames';
import * as React from 'react';
import { UILibraryComponent } from '../core';
import { UILibraryComponentProps } from '../core/types';

type DividerProps = UILibraryComponentProps;

export const Divider = React.forwardRef<HTMLHRElement, DividerProps>(
  (props, ref) => {
    const { className, ...otherProps } = props;

    return (
      <UILibraryComponent
        component="hr"
        ref={ref}
        className={classNames('hr-skin-strong', className)}
        {...otherProps}
      />
    );
  }
);

Divider.displayName = 'Divider';
