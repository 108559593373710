import classNames from 'classnames';
import * as React from 'react';
import { createUILibraryComponent, UILibraryComponent } from '../core';
import { NonHTMLProps, UILibraryComponentProps } from '../core/types';

//
// ─── CONTEXT ────────────────────────────────────────────────────────────────────
//

const textButtonContext = React.createContext<
  undefined | { registerIcon: () => void; unregisterIcon: () => void }
>(undefined);

const TextButtonContextProvider = textButtonContext.Provider;

function useTextButtonContext() {
  return React.useContext(textButtonContext);
}

/**
 * Tells an ancestor TextButton that there is an icon as a descendent
 *
 * This allows the TextButton to properly handle an icon-child
 *
 * If there is no TextButton ancestor, this does nothing
 */
export function useTextButtonIcon() {
  const textButtonContext = useTextButtonContext();

  const registerIcon = textButtonContext?.registerIcon;
  const unregisterIcon = textButtonContext?.unregisterIcon;

  React.useEffect(() => {
    if (registerIcon === undefined) {
      return;
    }

    registerIcon();

    return unregisterIcon;
  }, [registerIcon, unregisterIcon]);
}

//
// ─── TEXT BUTTON ────────────────────────────────────────────────────────────────
//

type TextButtonSkin = 'unstyled' | 'launcher' | 'primary' | 'secondary';
type TextButtonSizeVariant = 'standard' | 'super' | 'mini';
type TextButtonWidthVariant = 'standard' | 'full-width' | 'wide';
type TextButtonIconPosition = 'right' | 'left';

type TextButtonProps = UILibraryComponentProps<{
  /**
   * The visual style of the TextButton
   *
   * @default "secondary"
   */
  skin?: TextButtonSkin;

  /**
   * The size variation
   *
   * @default "standard"
   */
  sizeVariant?: TextButtonSizeVariant;

  /**
   * The width variation
   *
   * @default "standard"
   */
  widthVariant?: TextButtonWidthVariant;

  /**
   * The position of the icon in the TextButton
   *
   * @default "right"
   */
  iconPosition?: TextButtonIconPosition;
  to?: string;
}>;

export const TextButton = React.forwardRef<HTMLElement, TextButtonProps>(
  (props, ref) => {
    const {
      skin = 'secondary',
      iconPosition = 'right',
      sizeVariant = 'standard',
      widthVariant = 'standard',

      className,
      ...otherProps
    } = props;

    // certain skins are not "textbutton" skins and are treated differently (e.g. they don't need the textbutton base-class)
    const isTextbuttonSkin = !['unstyled', 'launcher'].includes(skin);

    // when a TextButtonIcon is rendered inside of a TextButton, it will `register` itself
    // this could get buggy if multiple TextButtonIcons are rendered inside of a single TextButton and then one is removed
    // but we don't support multiple-icon buttons, so it's not worth handling right now
    const [hasIcon, setHasIcon] = React.useState(false);

    const registerIcon = React.useCallback(() => {
      setHasIcon(true);
    }, []);

    const unregisterIcon = React.useCallback(() => {
      setHasIcon(false);
    }, []);

    return (
      <TextButtonContextProvider value={{ registerIcon, unregisterIcon }}>
        <UILibraryComponent
          component="button"
          type="button"
          ref={ref}
          className={classNames(
            { textbutton: isTextbuttonSkin },
            { [`textbutton-skin-${skin}`]: isTextbuttonSkin },
            { 'stylized-button-skin-unstyled': skin === 'unstyled' },
            { 'stylized-button-skin-launcher': skin === 'launcher' },
            { 'textbutton-super': sizeVariant === 'super' },
            { 'textbutton-mini': sizeVariant === 'mini' },
            { 'textbutton-full-width': widthVariant === 'full-width' },
            { 'textbutton-wide': widthVariant === 'wide' },
            { 'textbutton-with-icon': hasIcon },
            { 'textbutton-with-icon-left': hasIcon && iconPosition === 'left' },
            className
          )}
          {...otherProps}
        />
      </TextButtonContextProvider>
    );
  }
);

TextButton.displayName = 'TextButton';

//
// ─── TEXT BUTTON SUPER BREAK ────────────────────────────────────────────────────
//

export const TextButtonSuperBreak = createUILibraryComponent({
  displayName: 'TextButtonSuperBreak',
  defaultComponent: 'br',
  defaultProps: {
    className: 'textbutton-super-break',
  },
});

//
// ─── TEXT BUTTON ICON ───────────────────────────────────────────────────────────
//

type ForwardedTextButtonIconProps = NonHTMLProps<{
  /**
   * The type of icon to show
   */
  iconType: 'arrow-r' | 'arrow-l' | 'custom';
}>;

type TextButtonIconProps = UILibraryComponentProps &
  ForwardedTextButtonIconProps;

export const TextButtonIcon = React.forwardRef<
  HTMLElement,
  TextButtonIconProps
>((props, ref) => {
  const { iconType, className, ...otherProps } = props;

  // tell the parent TextButton that it has an icon child
  useTextButtonIcon();

  return (
    <UILibraryComponent
      component="span"
      ref={ref}
      className={classNames(
        'textbutton-icon',
        `textbutton-icon-${iconType}`,
        className
      )}
      {...otherProps}
    />
  );
});

TextButtonIcon.displayName = 'TextButtonIcon';
